import React from 'react';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/where_when.scss';

const WhereWhenPage = ({ data }) => (
    <Layout>
        <SEO title="where & when" />
        <div className="headerText wherewhen-headerText">
            where &amp;<br/> when
        </div>
        <div className="wherewhen-content">
            <p>
                Catch me at the Waukesha Farmer's Market.
            </p>
            <p>
                Saturdays. 8-12. 
                May 7th - October 29th.
            </p>
            <p>
              I'm serving up freshly brewed hot coffee, icy cold brew, and a rotating specialty drink.
            </p>
            <Img fixed={data.file.childImageSharp.fixed} className="whereWhenMarket" />
        </div>
    </Layout>
);

export const query = graphql`
query {
  file(relativePath: { eq: "where_when_market.jpg" }) {
    childImageSharp {
      fixed(width: 500) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`;

export default WhereWhenPage;